import { useLoading } from '@/context/LoadingContext';
import { ApolloError } from '@apollo/client';
import { CreateToastFnReturn, UseToastOptions, useToast as useShowToast } from '@chakra-ui/react';
import { runIfFn } from '@chakra-ui/utils';
import { TFunction } from 'i18next';
import { useCallback } from 'react';
import { assertNever } from '../types/types';

const defaultToastOptions: UseToastOptions = {
  isClosable: true,
  duration: 5_000,
};

type ReturnType = {
  toast: (options: UseToastOptions) => void;
  toastPromise: <R>(
    promise: Promise<R> | (() => Promise<R>),
    options: Parameters<CreateToastFnReturn['promise']>[1],
    useModalLoading?: boolean
  ) => Promise<R>;
};

export const toastPromiseOptions = (
  t: TFunction,
  theme: 'default' | 'create' | 'update' | 'delete' | 'save' | 'submit' = 'default',
  page?: 'task'
): Parameters<CreateToastFnReturn['promise']>[1] => {
  // bad requestの場合はサーバーから帰ってきたメッセージをそのまま表示する
  const error = (error: Error) => {
    if (error instanceof ApolloError) {
      const badRequestError = error.graphQLErrors.find(
        (error) => error.extensions?.code === 'BAD_REQUEST'
      );
      if (badRequestError) {
        return { title: badRequestError.message };
      }

      const conflictError = error.graphQLErrors.find((error) => error.extensions?.code === '409');
      if (conflictError) {
        return { title: conflictError.message, duration: 9000 };
      }

      const badUserInput = error.graphQLErrors.find(
        (error) => error.extensions?.code === 'BAD_USER_INPUT'
      );
      if (badUserInput) {
        // FIXME: ユーザ入力由来のエラーは BE から個別にメッセージを返すべきだが，ひとまず共通で入力不正であることを示す
        return { title: t('failed.invalid-value-specified', { ns: 'toasts' }) };
      }
    }

    return theme === 'default'
      ? { title: t('failed.failed', { ns: 'toasts' }) }
      : { title: t(`failed.${theme}`, { ns: 'toasts' }) };
  };

  switch (theme) {
    case 'default':
      return {
        success: {},
        error,
        loading: { title: t('in-progress.in-progress', { ns: 'toasts' }) },
      };
    case 'create':
    case 'update':
    case 'delete':
    case 'save':
    case 'submit':
      const path = [page, theme].flatMap((v) => (v ? [v] : [])).join('.');
      return {
        success: { title: t(`success.${path}`, { ns: 'toasts' }) },
        error,
        loading: { title: t(`in-progress.${path}`, { ns: 'toasts' }) },
      };
    default:
      assertNever(theme);
  }
};

export const useToast = (): ReturnType => {
  const showToast = useShowToast();
  const { loading } = useLoading();

  const toast = useCallback(
    (options: UseToastOptions): void => {
      showToast({ ...defaultToastOptions, ...options });
    },
    [showToast]
  );

  const toastPromise = useCallback<ReturnType['toastPromise']>(
    async (promise, options, useModalLoading = false) => {
      if (useModalLoading) loading(true);
      const id = showToast({
        ...options.loading,
        status: 'loading',
        duration: null,
      });

      try {
        const data = typeof promise === 'function' ? await promise() : await promise;

        const props = runIfFn(options.success, data);
        // successを出さないようにできないのでchakraのtoast.promiseを拡張する
        if (props.title) {
          showToast.update(id, {
            status: 'success',
            ...defaultToastOptions,
            ...props,
          });
        } else {
          showToast.close(id);
        }

        return data;
      } catch (error: unknown) {
        showToast.update(id, {
          status: 'error',
          ...defaultToastOptions,
          ...runIfFn(options.error, error as Error),
        });

        throw error;
      } finally {
        if (useModalLoading) loading(false);
      }
    },
    [showToast, loading]
  );

  return {
    toast,
    toastPromise,
  };
};
